import { GetStaticPaths, GetStaticProps } from 'next'
import { pageUtil } from '@/services/api/pageUtil'

export const getStaticPaths: GetStaticPaths = async () => {
  return pageUtil.getCampaignPageStaticPaths('launch.movie')
}

export const getStaticProps: GetStaticProps = async (context) => {
  return pageUtil.getCampaignPageProps({
    campaignSlug: context?.params?.slug as string,
    domain: 'launch.movie',
  })
}

export { default } from '@/components/pages/CampaignPages/CampaignPage'
